@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import "src/styles/colors";

.calendar-page {
  .calendar-component {
    .fc-time-grid-event {
      cursor: pointer;

      &:hover {
 } } }        //background-color: #abd250 !important


  .calendar-component__explanation {
    display: flex;
    justify-content: flex-end;

    div {
      margin-left: 30px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        /*background: red;*/
        left: -13px;
        top: 6px; }

      &:nth-child(1) {
        &:before {
          background: #3788d8; } }

      &:nth-child(2) {
        &:before {
          background: #abd250; } }

      &:nth-child(3) {
        &:before {
          background: #cdcdcd; } } } }

  &__go-back {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: $primary; } }

  &__name {
    font-size: 20px;
    font-weight: 600; } }
