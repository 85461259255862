@import '../../styles/colors.sass';

.loader {
  height: 28px;
  width: 28px;
  display: inline-flex;
  align-items: center;

  &__lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 29px;
    height: 8px;

    div {
      position: absolute;
      top: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $grey-darker;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 0; }

      &:nth-child(2) {
        left: 0; }

      &:nth-child(3) {
        left: 10px; }

      &:nth-child(4) {
        left: 20px; } }

    &--loading {
      div {
        background: $primary;
        &:nth-child(1) {
          animation: lds-ellipsis1 0.6s infinite; }

        &:nth-child(2) {
          animation: lds-ellipsis2 0.6s infinite; }

        &:nth-child(3) {
          animation: lds-ellipsis2 0.6s infinite; }

        &:nth-child(4) {
          animation: lds-ellipsis3 0.6s infinite; } } } } }


@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }

  100% {
    transform: scale(1); } }


@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }

  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }

  100% {
    transform: translate(10px, 0); } }

