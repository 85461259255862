@import '../../styles/colors.sass';

.nav-bar-top {
  width: 100%;
  height: 60px;
  background-color: $white;
  border-bottom: 2px solid $grey-lightest;
  display: flex;

  &__tog {
    width: 70px;
    min-width: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s ease-in-out;

    &--expand {
      width: 210px;
      min-width: 210px; }

    &_icon {
      cursor: pointer;
      outline: none;
      margin-left: 20px;

      .icon-dots {
        color: $grey-darker;
        transition: 0.3s ease-in-out;

        &--rotate {
          transform: rotate(90deg);
          transition: 0.2s ease-in-out; } }

      &:hover {
        .icon-dots {
          color: $primary; } } } }

  &__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 0 0; }

  &__avatar {
    display: flex;
    align-items: center;

    &_name {
      font-family: Roboto-light, sans-serif;
      color: $grey-dark;

      span {
        color: $black; } }

    &_logout {
      width: 86px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      color: $grey-dark;
      cursor: pointer;

      &:hover {
        color: $alert-danger; }

      .anticon {
        margin-left: 5px;

        &:hover {
          color: $alert-danger; } } }

    &_clip {
      width: 40px;
      height: 40px;
      clip-path: circle(50% at 50% 50%);

      img {
        object-fit: cover;
        width: 100%;
        height: 100%; } } } }




