@import "src/styles/colors";

.home-page-calendar {
  width: 100%;

  &__wrapper {
    background-color: white;
    padding: 10px 20px !important; }

  &__create-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px; }

  &__create {
    cursor: pointer;

    &:hover {
      span {
        color: $primary; } }

    span {
      color: gray; }


    svg {
      margin-right: 5px; } }

  &__delete-slot {
    cursor: pointer;
    margin-left: 10px;

    svg {
      color: gray;

      &:hover {
        color: red; } } } }

.ant-modal-body {
  max-height: 750px;
  overflow-y: auto;

  .ant-form-item-label {
    white-space: normal; } }

.ant-picker-dropdown {
  .ant-picker-now {
    display: none; } }



