//------THEME COLORS--------//
$primary: #1abc9c;
$primary-dark: #16a085;

$secondary: #3498db;
$secondary-dark: #2980b9;

$white: #ffffff;
$white-darker: #fcfcfc;
$grey-border: #e9ebee;
$grey-lightest: #f5f7fa;
$grey-pre-medium: #f4f4f6;
$grey-medium: #cfdae6;
$grey-medium-dark: #bfccd9;
$grey-dark: #adbdcc;
$grey-darker: #8595a6;
$grey-darkest: #475259;
$black: #1e2225;
$black-blue: rgba(35, 42, 96, 0.63);

//------ALERTS--------//
$alert-success: #0dbf66;
$alert-info: #4c6ef5;
$alert-warning: #fcc419;
$alert-danger: #ed5556;

//------GRADIENTS--------//
$gradient: linear-gradient(to right, #7d87ff 0%, #4952b8 100%);
$gradient-negative: linear-gradient(to right, #f24c15 0%, #ff7528 100%);
$gradient-black: linear-gradient(to right, #f24c15 0%, #ff7528 100%);

//------OTHER--------//
$facebook: #0dbf66;
$facebook-dark: #25417b;
