@import '../../styles/colors.sass';

.reset-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    width: 430px;
    margin: 20px;

    input {
      margin-bottom: 20px; } }

  &__general-error {
    border: 1px solid $alert-danger;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 20px;
    background-color: $alert-danger;
    color: white;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15); }

  form {
    div {
      display: flex;
      justify-content: space-between;

      a {
        color: $grey-dark;
        &:hover {
          color: $primary; } } } } }
