@import "src/styles/colors";

.user-page-calendar {
  width: 100%;

  &__wrapper {
    background-color: white;
    padding: 10px 20px !important; }

  &__create-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px; }

  &__create {
    cursor: pointer;

    &:hover {
      span {
        color: $primary; } }

    span {
      color: gray; }


    svg {
      margin-right: 5px; } } }
