@import './styles/colors.sass';

.app {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__children {
    width: 100%; }

  &__wrapper {
    display: flex;
    height: 100%; } }

.route-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 58px);
  padding: 20px;
  background-color: $grey-lightest;
  overflow: auto;

  &--no-padding-left {
    padding: 0;
    height: calc(100vh - 62px); } }

::-webkit-scrollbar {
  width: 7px;
  height: 5px; }
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $grey-dark; }
::-webkit-scrollbar-thumb {
  background-color: $grey-dark; }

.suspense-loading-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.select {
  .css-1n7v3ny-option {
    &:hover {
      background-color: lighten($primary, 50%); } } }

